<template>
  <div>

    <!-- #region::Table -->
    <vue-good-table
      ref="pieces-table"
      class="vgt-table-checkbox-styled"
      :columns="columns"
      :rows="pieces"
      style-class="vgt-table condensed vgt-checkbox-col"
      :line-numbers="true"
      :select-options="{
        enabled: selectable,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdown: [3, 5, 10],
        dropdownAllowAll: true,
        jumpFirstOrLast : true,
        firstLabel : 'Primera página',
        lastLabel : 'Última página',
        nextLabel: 'Siguiente',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Piezas por página',
        ofLabel: 'de',
        allLabel: 'Todo',
      }"
      @on-selected-rows-change="selectionChanged"
    >

      <!-- #region::Empty response -->
      <div slot="emptystate">
        This will show up when there are no rows
      </div>
      <!-- #endregion::Empty response -->

      <!-- #region::Column headers -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          <h6 class="capitalize-col-hd"><strong>{{ props.column.label }}</strong></h6>
        </span>
      </template>
      <!-- #endregion::Column headers -->

      <!-- #region::Modifying rows cells -->
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- #region::Availability cell -->
        <span v-if="props.column.field === 'Tag'">
          <div class="d-flex justify-content-start">
            <h6>{{ props.row.Tag }}</h6>
            <div
              v-if="props.row.Delivered === 'Si'"
              class="sm-banner-alert alert-cyan"
              role="alert"
            >
              Entrega
            </div>
            <div
              v-if="props.row.Delivered === 'Entregado'"
              class="sm-banner-alert alert-cyan"
              role="alert"
            >
              Entregada
            </div>
            <div
              v-if="showRefundTag && props.row.Devolution === 'Si'"
              class="sm-banner-alert alert-marine"
              role="alert"
            >
              En devolución
            </div>
          </div>
        </span>
        <!-- #endregion::Availability cell -->

        <!-- #region::Availability cell -->
        <span v-else-if="props.column.field === 'Availability'">
          <h6><b-badge
            class="ava-label"
            :variant="getAvailabilityStyle(props.row.Availability)"
          >
            {{ props.row.Availability }}
          </b-badge></h6>
        </span>
        <!-- #endregion::Availability cell -->

        <!-- #region::Arrive date cell -->
        <span v-else-if="props.column.field === 'ArriveDate'">
          <h6 class="ava-label">{{ props.row.ArriveDate | moment("DD/MM/YYYY") }}</h6>
        </span>
        <!-- #endregion::Arrive date cell -->

        <!-- #region::Actions column -->
        <span v-else-if="props.column.field === 'actions'">

          <!-- #region::Delete circuit button -->
          <b-button
            v-if="props.row.Delivered !== 'Si' && props.row.Delivered !== 'Entregado'"
            v-b-tooltip.hover.top="'Editar'"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="$refs.updatePieceModal.showModal(props.row)"
          >
            <feather-icon icon="Edit3Icon" />
          </b-button>
          <!-- #endregion::Delete circuit button -->

          <!-- #region::Delete circuit button -->
          <b-button
            v-b-tooltip.hover.top="'Eliminar'"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="deletePiece(props.row)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <!-- #endregion::Delete circuit button -->

        </span>
        <!-- #endregion::Actions column -->

        <!-- #region::Rest of columns -->
        <span v-else>
          <h6>{{ props.formattedRow[props.column.field] }}</h6>
        </span>
        <!-- #endregion::Rest of columns -->

      </template>
      <!-- #endregion::Modifying rows cells -->

    </vue-good-table>
    <!-- #endregion::Table -->

    <UpdatePieceModal
      ref="updatePieceModal"
      :product-id="product.IdProduct"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BBadge, BButton, VBTooltip } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import UpdatePieceModal from '@/modules/trade/orders/components/modals/UpdatePieceModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BButton,
    VueGoodTable,
    UpdatePieceModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    pieces: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },

    // * Conditional columns
    showInvoiceColumn: {
      type: Boolean,
      default: false,
    },
    showActionsButton: {
      type: Boolean,
      default: false,
    },
    showRefundTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'Tag',
          label: 'Tag',
          hidden: !this.$ability.can('read', 'Piece_tag'),
        },
        {
          field: 'NoSeries',
          label: 'No. serie',
          hidden: !this.$ability.can('read', 'Piece_serie'),
        },
        {
          field: 'NoPetition',
          label: 'No. Pedimento',
          hidden: !this.$ability.can('read', 'Piece_petition'),
        },
        {
          field: 'NoInvoice',
          label: 'No. factura',
          hidden: !this.showInvoiceColumn
            || !this.$ability.can('read', 'Piece_invoice'),
        },
        {
          field: 'NoImport',
          label: 'No. importación',
          hidden: !this.$ability.can('read', 'Piece_import'),
        },
        {
          field: 'Condition',
          label: 'Condición',
          hidden: !this.$ability.can('read', 'Piece_condition'),
        },
        {
          field: 'Availability',
          label: 'Disponibilidad',
          hidden: !this.$ability.can('read', 'Piece_availability'),
        },
        {
          field: 'Location',
          label: 'Ubicación',
          hidden: !this.$ability.can('read', 'Piece_location'),
        },
        {
          field: 'ArriveDate',
          label: 'Fecha de llegada',
          hidden: !this.$ability.can('read', 'Piece_arrival_date'),
        },
        {
          field: 'actions',
          label: 'Acciones',
          hidden: !this.showActionsButton,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getDetailProducts: 'orders/getDetailProducts',
      getEditionModeState: 'orders/getEditionModeState',
    }),
    orderProducts: {
      get() { return this.getDetailProducts },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    orderProduct() {
      return this.orderProducts.find(orderProduct => orderProduct.IdProduct === this.product.IdProduct)
    },
  },
  watch: {
    editionModeState(state) {
      this.$set(this.columns[9], 'hidden', !state)
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('rendering', false)
    })
  },
  methods: {
    ...mapActions({
      updateDetailProduct: 'orders/updateDetailProduct',
      addPartialDeliveryProvitionalProduct: 'orders/addPartialDeliveryProvitionalProduct',
    }),
    getAvailabilityStyle(availability) {
      switch (availability) {
        case 'Disponible':
          return 'light-success'
        case 'No disponible':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },
    selectionChanged(params) {
      const provitionalProduct = { ...this.product }
      provitionalProduct.pieces = params.selectedRows.map(piece => ({ ...piece }))

      this.addPartialDeliveryProvitionalProduct(provitionalProduct)
    },
    deletePiece(piece) {
      if (piece.Delivered === 'Entregado') {
        this.showToast(
          'Error de validación',
          'La pieza no puede ser eliminada ya que ha sido entregada.',
          'warning',
        )

        return
      }

      if (this.orderProduct) {
        const filteredPieces = this.orderProduct.pieces.filter(
          quoteProductPiece => quoteProductPiece.IdPiece !== piece.IdPiece,
        )

        this.orderProduct.pieces = filteredPieces.map(filteredPiece => ({ ...filteredPiece }))
        this.updateDetailProduct(this.orderProduct)
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
